import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      flexWrap: 'nowrap !important'
    },
    '&.short': {
      '& .MuiOutlinedInput-root': {
        '& input, & .MuiAutocomplete-endAdornment': {
          display: 'none'
        }
      }
    }
  },
  input: {
    minWidth: `${theme.spacing(5)} !important`
  },
  groupHeader: {
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    background: 'white',
    textTransform: 'capitalize'
  },
  groupItems: {
    whiteSpace: 'nowrap',
    padding: 0
  },
  emptyItem: {
    padding: 0,
    textAlign: 'center'
  },
  optionItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.grey[100],
    }
  }
}));

export default useStyles;
