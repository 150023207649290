import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PushPinIcon from '@mui/icons-material/PushPin';
import ViewListIcon from '@mui/icons-material/ViewList';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';

// Manage BUSINESSES
// Meta Key
export const DEFAULT_PARAM_METADATA_BUSINESSES = {
  metaKey: `manageContactBusinessPinIds,manageContactBusinessColumnDisplay,manageContactBusinessSectionDisplay`
};
export const META_PIN_BUSINESS = 'manageContactBusinessPinIds';
export const META_SECTION_BUSINESS = 'manageContactBusinessSectionDisplay';
export const META_COLUMN_BUSINESS = 'manageContactBusinessColumnDisplay';
// Section key
export const MANAGE_BUSINESSES = 'manageBusinesses';
export const NEW_BUSINESS = 'newBusiness';
// export const IMPORT_CUSTOMER = 'importCustomer';
export const PIN_BUSINESSES = 'pinBusinesses';
export const MANAGE_BUSINESS_SECTIONS_VALUE = [
  NEW_BUSINESS,
  PIN_BUSINESSES,
  MANAGE_BUSINESSES
];
export const MANAGE_BUSINESS_SECTIONS = [
  {
    label: 'New Business',
    value: 'newBusiness',
    order: 1,
    icon: <PlaylistAddIcon />
  },
  // {
  //   label: 'Import Customer',
  //   value: 'importCustomer',
  //   order: 2,
  //   icon: <VisibilityIcon />
  // },
  {
    label: 'Pin Businesses',
    value: 'pinBusinesses',
    order: 2,
    icon: <PushPinIcon />
  },
  {
    label: 'Manage Businesses',
    value: 'manageBusinesses',
    order: 3,
    icon: <ViewListIcon />
  }
];
// Columns

export const MANAGE_BUSINESSES_COLUMNS = [
  { id: 'pin', label: 'Pin', align: 'center', isDefault: true, order: 1 },
  { id: 'id', label: 'Business #', isDefault: true, order: 2 },
  {
    id: 'name',
    label: 'Business name',
    isDefault: true,
    order: 3
  },
  {
    id: 'postCode',
    label: 'Post code',
    isDefault: false,
    order: 4
  },
  {
    id: 'stateCode',
    label: 'State code',
    isDefault: false,
    order: 5
  },
  {
    id: 'address1',
    label: 'Address 1',
    isDefault: false,
    order: 7
  },
  {
    id: 'address2',
    label: 'Address 2',
    isDefault: false,
    order: 8
  },
  {
    id: 'countryCode',
    label: 'Country code',
    isDefault: false,
    order: 9
  },
  {
    label: 'City',
    id: 'city',
    isDefault: false,
    order: 10
  },
  {
    id: 'faxNumber',
    label: 'Fax number',
    isDefault: false,
    order: 11
  },
  {
    id: 'websiteAddress',
    label: 'Website address',
    isDefault: false,
    order: 12
  },
  {
    id: 'businessGlobalId',
    label: 'Business global id',
    isDefault: false,
    order: 13
  },
  {
    id: 'type',
    label: 'Type',
    isDefault: false,
    order: 14
  },

  { label: 'Email address', id: 'emailAddress', isDefault: false, order: 15 },
  { label: 'Phone number', id: 'phoneNumber', isDefault: false, order: 16 },

  {
    id: 'created',
    label: 'Created',
    format: (value) => dayjs(value).format('MM/YY'),
    isDefault: false,
    order: 17
  },
  {
    label: 'Updated',
    id: 'updated',
    format: (value) => dayjs(value).format('MM/YY'),
    isDefault: false,
    order: 18
  }
];

export const DEFAULT_PARAMS_SEARCH_BUSINESS = {
  s: '',
  name: '',
  createdFrom: '',
  createdTo: '',
  updatedFrom: '',
  updatedTo: ''
};

// Manage Mebers
// Meta Key
export const DEFAULT_PARAM_METADATA_MEMBERS = {
  metaKey: `manageContactMemberPinIds,manageContactMemberColumnDisplay,manageContactMemberSectionDisplay`
};
export const META_PIN_MEMBER = 'manageContactMemberPinIds';
export const META_SECTION_MEMBER = 'manageContactMemberSectionDisplay';
export const META_COLUMN_MEMBER = 'manageContactMemberColumnDisplay';
// Section key
export const MANAGE_MEMBERS = 'manageMembers';
export const NEW_MEMBER = 'newMember';
// export const IMPORT_CUSTOMER = 'importCustomer';
export const PIN_MEMBERS = 'pinMembers';
export const MANAGE_MEMBER_SECTIONS_VALUE = [
  NEW_MEMBER,
  PIN_MEMBERS,
  MANAGE_MEMBERS
];
export const MANAGE_MEMBER_SECTIONS = [
  {
    label: 'New Member',
    value: 'newMember',
    order: 1,
    icon: <PlaylistAddIcon />
  },
  // {
  //   label: 'Import Customer',
  //   value: 'importCustomer',
  //   order: 2,
  //   icon: <VisibilityIcon />
  // },
  {
    label: 'Pin Members',
    value: 'pinMembers',
    order: 2,
    icon: <PushPinIcon />
  },
  {
    label: 'Manage Members',
    value: 'manageMembers',
    order: 3,
    icon: <ViewListIcon />
  }
];
// Columns

export const MANAGE_MEMBER_COLUMNS = [
  { id: 'pin', label: 'Pin', align: 'center', isDefault: true, order: 1 },
  { id: 'id', label: 'Member #', isDefault: true, order: 2 },
  {
    id: 'firstName',
    label: 'First Name',
    isDefault: true,
    order: 3
  },
  {
    id: 'lastName',
    label: 'Last Name',
    isDefault: true,
    order: 4
  },
  {
    id: 'postCode',
    label: 'Post code',
    isDefault: false,
    order: 5
  },
  {
    id: 'stateCode',
    label: 'State code',
    isDefault: false,
    order: 6
  },
  {
    id: 'address1',
    label: 'Address 1',
    isDefault: false,
    order: 7
  },
  {
    id: 'address2',
    label: 'Address 2',
    isDefault: false,
    order: 8
  },
  {
    id: 'countryCode',
    label: 'Country code',
    isDefault: false,
    order: 9
  },
  {
    label: 'City',
    id: 'city',
    isDefault: false,
    order: 10
  },
  { label: 'Email address', id: 'emailAddress', isDefault: false, order: 11 },
  { label: 'Phone number', id: 'phoneNumber', isDefault: false, order: 12 },
  {
    id: 'created',
    label: 'Created',
    format: (value) => dayjs(value).format('MM/YY'),
    isDefault: false,
    order: 15
  },
  {
    label: 'Updated',
    id: 'updated',
    format: (value) => dayjs(value).format('MM/YY'),
    isDefault: false,
    order: 16
  }
];

export const DEFAULT_PARAMS_SEARCH_MEMBERS = {
  s: '',
  name: '',
  createdFrom: '',
  createdTo: '',
  updatedFrom: '',
  updatedTo: ''
};

export const CATEGORY_MEMBER = 'contact-member';
export const CATEGORY_BUSINESS = 'contact-business';

export const requestTitleDefine = {
  lop: 'Letter of Protection',
  lob: 'Incurred Charges',
  general: 'General Request',
  affidavit: 'Affidavit Request'
};

export const BUSINESS_CODE ={
  ATTORNEY: 'attorney',
  DOCTOR: 'doctor',
  PHARMACY: 'pharmacy',
  MEMBER: 'member',
};
