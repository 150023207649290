import * as React from 'react';

import { matchPath, useLocation } from 'react-router-dom';
import {
  selectMyProfile,
  selectUserMetadata
} from 'portals/business/store/selectors';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Chip from 'components/Chip';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from 'components/List/Item/Button';
import ListItemIcon from 'components/List/Item/Icon';
import ListItemText from 'components/List/Item/Text';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchCaseAutocomplete from 'portals/business/components/SearchCaseAutocomplete';
import SelectBusinessDropdown from 'portals/business/components/SelectBusinessDropdown';
import SidebarFooter from './SidebarFooter';
import Typography from 'components/Typography';
import navbarList from './navList';
import { setUserMetadata } from 'portals/business/store/actions';
import { useState } from 'react';
import useStyles from './styled';
import { useTheme } from '@mui/material/styles';
import { isPersonalPortal } from 'helpers';

const drawerWidthOpen = 240;
const drawerWidthClose = 56;

export default function SideNavbar({ onLogout, setFixedSidebar }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { pathname } = useLocation();
  const classes = useStyles();
  const profile = useSelector((state) => selectMyProfile(state));
  const userMetadata = useSelector((state) => selectUserMetadata(state));
  const [isFixedPosition, setDrawerPosition] = useState(
    !userMetadata[profile?.id]?.isFixedSidebar
  );
  const [open, setOpen] = useState(!userMetadata[profile?.id]?.isFixedSidebar);

  const defaultSubMenuOpen = Array(navbarList.length).fill(false);

  const [subMenuOpen, setSubMenuOpen] = useState(defaultSubMenuOpen);

  const isActiveItem = (path) => {
    if (!path) return false;
    return matchPath(path, pathname);
  };

  const isSubActiveItem = (subList) => {
    if (subList.length === 0) return false;
    return subList.some((item) => isActiveItem(item.path));
  };

  const toggleSidebar = () => {
    setDrawerPosition(!isFixedPosition);
    setFixedSidebar(!isFixedPosition);
    dispatch(
      setUserMetadata({
        [profile?.id]: {
          isFixedSidebar: isFixedPosition
        }
      })
    );
  };

  function closeDrawer() {
    if (!open && isFixedPosition) {
      setOpen(true);
    }
  }

  function openDrawer() {
    if (open) {
      setOpen(false);
    }
  }

  const handleClickItem = (e, key, index) => {
    if (key.subList.length > 0) {
      e.preventDefault();
      const newSubMenuOpen = [...subMenuOpen];
      newSubMenuOpen[index] = !subMenuOpen[index];
      setSubMenuOpen([...newSubMenuOpen]);
    }
  };

  React.useEffect(() => {
    const index = subMenuOpen.findIndex((item) => item === true);
    if (index > -1) {
      const menu = navbarList[index];
      if (!isActiveItem(menu.path) && !isSubActiveItem(menu.subList)) {
        setSubMenuOpen([...defaultSubMenuOpen]);
      }
    }
  }, [pathname]);
  const drawerContent = (
    <>
      <Box
        sx={{
          display: 'flex',
          height: '42px',
          width: 'auto',
          backgroundColor: 'transparent',
          marginTop: '15px',
          padding: '12px 0px',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            flexShrink: 0,
            display: { xs: 'initial', sm: 'initial' },
            marginBottom: '9px',
            marginTop: 1.5,
            marginLeft: 1.5
          }}
        >
          <AddModeratorIcon style={{ fontSize: '30px' }} />
        </Box>
        <Typography
          variant="h1"
          noWrap={true}
          gutterBottom
          sx={{
            display: !open ? 'initial' : 'none',
            fontSize: '18px',
            fontWeight: 600,
            color: '#262626',
            width: '154px',
            marginLeft: open ? '0px' : '8px',
            paddingBottom: '3px'
          }}
        >
          LOPRX
        </Typography>
        {!open && (
          <Button
            onClick={toggleSidebar}
            sx={{
              minWidth: 'initial',
              padding: '4px',
              color: '#c3c3c3',
              borderRadius: '8px',
              backgroundColor: open ? 'transparent' : 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.textColor['grey'],
                '& svg': {
                  color: 'black'
                }
              }
            }}
          >
            {!isFixedPosition ? (
              <ChevronLeftIcon sx={{ fontSize: '20px' }} />
            ) : (
              <ChevronRightIcon sx={{ fontSize: '20px' }} />
            )}
          </Button>
        )}
      </Box>
      <SearchCaseAutocomplete short={open} />
      <List sx={{ flex: 1 }}>
        {navbarList.map((key, index) => (
          <>
            <ListItemButton
              className={
                (isActiveItem(key?.path) || isSubActiveItem(key.subList)) &&
                classes.active
              }
              sx={{
                margin: '6px 14px',
                padding: '6px 10px',
                borderRadius: '8px',
                marginLeft: '4px',

                '&:hover': {
                  backgroundColor: 'rgba(24, 119, 242, 0.12)',
                  '& .MuiSvgIcon-root, .MuiListItemText-root': {
                    color: 'rgba(28,43,51,1)'
                  }
                }
              }}
              component={Link}
              to={key?.path ?? '/'}
              key={`${key?.path}-${index}`}
              onClick={(e) => {
                handleClickItem(e, key, index);
              }}
              // selected={subMenuOpen[index]}
            >
              <ListItemIcon sx={{ minWidth: '46px' }}>
                <Badge badgeContent={key.badge} variant="dot">
                  <key.icon
                    sx={{
                      fontSize: '24px',
                      color: 'rgba(28,43,51,1)'
                    }}
                  />
                </Badge>
              </ListItemIcon>

              <ListItemText
                primary={key.desc}
                primaryTypographyProps={{
                  variant: 'body2'
                }}
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  display: 'inline',
                  margin: '0px',
                  overflowX: 'hidden',
                  color: 'rgba(28,43,51,1)',
                  whiteSpace: 'nowrap',
                  minWidth: '126px',
                  '&:hover': {
                    color: 'white'
                  }
                }}
              />
              {key.badge !== 0 ? (
                <Chip
                  label={key.badge}
                  color={'secondary'}
                  size="small"
                  sx={{ height: 'auto' }}
                />
              ) : (
                <></>
              )}
              {key.subList.length > 0 &&
                (subMenuOpen[index] ? <RemoveIcon /> : <AddIcon />)}
            </ListItemButton>

            {key.subList.length > 0 && !open && (
              <Collapse
                in={subMenuOpen[index]}
                timeout="auto"
                unmountOnExit
                sx={{ pl: 2 }}
              >
                <List component="div" disablePadding>
                  {key.subList.map((subKey, subIndex) => (
                    <ListItemButton
                      className={isActiveItem(subKey?.path) && classes.active}
                      sx={{
                        margin: '6px 14px',
                        padding: '6px 10px',
                        borderRadius: '8px',
                        marginLeft: '4px',

                        '&:hover': {
                          backgroundColor: '#f3f3f3',
                          '& .MuiSvgIcon-root, .MuiListItemText-root': {
                            color: 'rgba(28,43,51,1)'
                          }
                        }
                      }}
                      component={Link}
                      to={subKey?.path ?? '/'}
                      key={`${subKey?.path}-${subIndex}`}
                    >
                      <ListItemIcon sx={{ minWidth: '46px' }}>
                        <Badge badgeContent={subKey.badge} variant="dot">
                          <subKey.icon
                            sx={{
                              fontSize: '24px',
                              color: 'rgba(28,43,51,1)'
                            }}
                          />
                        </Badge>
                      </ListItemIcon>
                      <ListItemText
                        primary={subKey.desc}
                        primaryTypographyProps={{
                          variant: 'body2'
                        }}
                        sx={{
                          fontWeight: 500,
                          fontSize: '14px',
                          display: 'inline',
                          margin: '0px',
                          overflowX: 'hidden',
                          color: 'rgba(28,43,51,1)',
                          whiteSpace: 'nowrap',
                          minWidth: '126px',
                          '&:hover': {
                            color: 'white'
                          }
                        }}
                      />
                      {subKey.badge !== 0 ? (
                        <Chip
                          label={subKey.badge}
                          color={'secondary'}
                          size="small"
                          sx={{ height: 'auto' }}
                        />
                      ) : (
                        <></>
                      )}
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </>
        ))}
      </List>
      {!isPersonalPortal() && <SelectBusinessDropdown short={open} />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          alignContents: 'center',
          margin: '0 4px 14px',
          padding: '12px 4px'
        }}
      >
        <SidebarFooter
          closeDrawer={closeDrawer}
          isSidebarOpen={!open}
          onLogout={onLogout}
        />
      </Box>
    </>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        position: isFixedPosition ? 'fixed' : 'relative',
        zIndex: 999
      }}
      onMouseOver={openDrawer}
      onMouseLeave={closeDrawer}
    >
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          // width: open
          //   ? { xs: '0px', sm: drawerWidthClose }
          //   : { xs: drawerWidthClose, sm: drawerWidthOpen },
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: open
              ? theme.transitions.duration.leavingScreen
              : theme.transitions.duration.enteringScreen
          }),
          '& .MuiDrawer-paper': {
            overflowX: 'hidden',
            width: open
              ? { xs: drawerWidthClose, sm: drawerWidthClose }
              : { xs: drawerWidthOpen, sm: drawerWidthOpen },
            borderRight: '0px',
            boxShadow: theme.loprxStyles.lightShadow,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: open
                ? theme.transitions.duration.leavingScreen
                : theme.transitions.duration.enteringScreen
            }),
            backgroundColor: '#f0f2f5'
          }
        }}
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
}
