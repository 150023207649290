import 'portals/business/configs/I18n';
import '../../App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { PrivateLayout, PublicLayout } from 'components/Layout';
import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

/* eslint-disable react-hooks/exhaustive-deps */
import { Buffer } from 'buffer';
import { PrivateRoute } from 'components/Routes';
import Wrapper from 'components/Layout/Wrapper';
import { cbChangeBusiness } from 'helpers/common';
import { pdfjs } from 'react-pdf';

window.Buffer = Buffer;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
/**
 * @lazy load import Pages
 */
const Home = lazy(() => import('portals/business/pages/Home'));
const Login = lazy(() => import('portals/business/pages/Login'));
const SignUp = lazy(() => import('portals/business/pages/SignUp'));
const TwoFactorVerify = lazy(() =>
  import('portals/business/pages/TwoFactorVerify')
);
const Profile = lazy(() => import('portals/business/pages/Profile'));
const ForgotPassword = lazy(() =>
  import('portals/business/pages/ForgotPassword')
);
const ActiveUser = lazy(() => import('portals/business/pages/ActiveUser'));
const CreateCase = lazy(() => import('portals/business/pages/CreateCase'));
const CreateRequest = lazy(() =>
  import('portals/business/pages/CreateRequest')
);
const Cases = lazy(() => import('portals/business/pages/Cases'));
const Customers = lazy(() => import('portals/business/pages/Customers'));
const Businesses = lazy(() => import('portals/business/pages/Businesses'));
const Members = lazy(() => import('portals/business/pages/Members'));
const Requests = lazy(() => import('portals/business/pages/Requests'));
const SelectBusiness = lazy(() =>
  import('portals/business/pages/SelectBusiness')
);
const ModalWrapper = lazy(() => import('components/ModelWrapper'));
const CaseDetail = lazy(() => import('portals/business/pages/CaseDetail'));
const CustomerDetail = lazy(() =>
  import('portals/business/pages/CustomerDetail')
);
const MemberDetail = lazy(() => import('portals/business/pages/MemberDetail'));
const BusinessDetail = lazy(() =>
  import('portals/business/pages/BusinessDetail')
);
const RequestDetail = lazy(() =>
  import('portals/business/pages/RequestDetail')
);
const PageNotFound = lazy(() => import('portals/business/pages/404'));
const PermissionDenied = lazy(() => import('portals/business/pages/403'));
const InternalServer = lazy(() => import('portals/business/pages/500'));
const TermsConditions = lazy(() =>
  import('portals/business/pages/TermsConditions')
);
function App() {
  useEffect(() => {
    const changeLocalStorage = (evt) => {
      if (evt?.key === 'bId') {
        cbChangeBusiness();
      }
    };
    // reload other tab when businessId has been changed
    window.addEventListener('storage', changeLocalStorage);
    return () => {
      window.removeEventListener('storage', changeLocalStorage);
    };
  }, []);

  return (
    <div className="App">
      <Wrapper>
        <Routes>
          <Route element={<PublicLayout />}>
            <Route path="/sign-up" exact element={<SignUp />} />
            <Route path="/sign-in" exact element={<Login />} />
            <Route path="/forgot-password" exact element={<ForgotPassword />} />
            <Route
              path="/two-factor/:mfaAuth"
              exact
              element={<TwoFactorVerify />}
            />
          </Route>
          <Route element={<PrivateLayout />}>
            <Route path="/" element={<PrivateRoute Component={Home} />} />
            <Route
              exact
              path="/profile"
              element={<PrivateRoute Component={Profile} />}
            />
            <Route
              exact
              path="/active-user"
              element={<PrivateRoute Component={ActiveUser} />}
            />
            <Route
              exact
              path="/dashboard"
              element={<PrivateRoute Component={Home} />}
            />
            <Route path="/create-case" exact element={<CreateCase />} />
            <Route path="/create-request" exact element={<CreateRequest />} />
            <Route path="/cases" exact element={<Cases />} />
            <Route
              exact
              path="/select-business"
              element={<PrivateRoute Component={SelectBusiness} />}
            />
            <Route
              path="/cases/:id/"
              exact
              element={<PrivateRoute Component={CaseDetail} />}
            />
            <Route
              path="/customers/:id/"
              exact
              element={<PrivateRoute Component={CustomerDetail} />}
            />
            <Route
              path="/businesses/:id/"
              exact
              element={<PrivateRoute Component={BusinessDetail} />}
            />
            <Route
              path="/requests/:id/"
              exact
              element={<PrivateRoute Component={RequestDetail} />}
            />
            <Route
              path="/members/:id/"
              exact
              element={<PrivateRoute Component={MemberDetail} />}
            />
            <Route path="/customers" exact element={<Customers />} />
            <Route path="/businesses" exact element={<Businesses />} />
            <Route path="/members" exact element={<Members />} />
            <Route path="/requests" exact element={<Requests />} />
          </Route>
          <Route path="/403" element={<PermissionDenied />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/500" element={<InternalServer />} />
          <Route
            path="/privacy-policy-and-terms"
            element={<TermsConditions />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Wrapper>
      <Suspense>
        <ModalWrapper />
      </Suspense>
    </div>
  );
}

export default App;
