import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import clsx from 'classnames';
import debounce from 'lodash/debounce';

import API from 'api';
import AvatarBusiness from 'components/Avatar/Business';
import { selectMyBusiness } from 'portals/business/store/selectors';
import { getMyBusinessToken } from 'portals/business/store/actions';
import { cbChangeBusiness } from 'helpers/common';
import { styled, lighten, darken } from '@mui/system';
import { formatClientName } from 'helpers/common';
import Popper from '@mui/material/Popper';

import { ROUTE_CASE } from 'portals/business/routes';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from 'components/AutoComplete';
import Input from 'components/Input';
import Select from 'components/Select';
import MenuItem from 'components/MenuItem';
import Button from 'components/Button';

import useStyles from './styled';

const LIMITED_CASES = 5;
const LIMITED_CUSTOMERS = 5;

const PopperMy = (props) => <Popper {...props} sx={{ minWidth: 300 }} placement="bottom-start" />;

export default ({ short = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();

  const [cases, setCases] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCases = async (val = '') => {
    !loading && setLoading(true);
    const res = await API.getCases({ s: val });
    setCases((res?.data || []).slice(0, LIMITED_CASES));
    setLoading(false);
  };

  const getCustomers = async (val = '') => {
    !loading && setLoading(true);
    const res = await API.getCustomers({ s: val });
    setCustomers((res?.data || []).slice(0, LIMITED_CUSTOMERS));
    setLoading(false);
  };

  const onChangeInput = debounce((e) => {
    const { value = '' } = e?.target || {};
    getCases(value);
    getCustomers(value);
  }, 300);

  const onChange = (evt, item) => {
    setSelected(item);
    switch (item?.type) {
      case 'case':
        navigate(ROUTE_CASE.CASE_DETAIL(item?.id));
        break;
      case 'customer':
        navigate(`/customers/${item?.id}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onChangeInput({});
  }, []);

  const options = useMemo(() => [
    ...cases.map(c => ({ ...c, label: c?.title, type: 'case' })),
    ...customers.map(c => ({ ...c, label: formatClientName(c), type: 'customer' })),
  ], [cases, customers]);

  return (
    <Autocomplete
      className={clsx(classes.autocomplete, short && 'short')}
      PopperComponent={PopperMy}
      loading={loading}
      defaultValue={selected}
      value={selected}
      autoHighlight
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      onChange={(evt, item) => onChange(evt, item, true)}
      options={options}
      maxHeight={400}
      renderInput={(e) => (
        <Input
          {...e}
          className={classes.input}
          onChange={onChangeInput}
          InputProps={{
            ...e?.InputProps,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start" sx={{ height: 'auto', margin: 0 }}>
                <SearchIcon />
              </InputAdornment>
            )
          }}
          placeholder="Search case/customer"
          size="small"

        />
      )}
      groupBy={(option) => option?.type}
      // renderOption={(option, item) => (
      //   <div className={classes.optionItem} onClick={e => onChange(e, item)}>
      //     {option.key}
      //   </div>
      // )}
      renderGroup={(e) => (
        <li>
          <div className={classes.groupHeader}>
            {e.group}
          </div>
          {e?.children?.length
            ? (
              <ul className={classes.groupItems}>
                {e.children}
              </ul>
            )
            : (
              <ul className={classes.emptyItem}>
                No data
              </ul>
            )}
        </li>
      )}
    />
  );
};
