import BusinessIcon from '@mui/icons-material/Business';
import ContactsIcon from '@mui/icons-material/Contacts';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MemoryIcon from '@mui/icons-material/Memory';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
const navbarList = [
  {
    icon: HomeOutlinedIcon,
    desc: 'Home',
    secondDesc: '',
    badge: 0,
    subList: [],
    path: '/'
  },
  {
    icon: SignalCellularAltOutlinedIcon,
    desc: 'Cases',
    secondDesc: '',
    badge: 0,
    subList: [],
    path: '/cases'
  },
  {
    icon: MemoryIcon,
    desc: 'Requests',
    secondDesc: '',
    badge: 0,
    subList: [],
    path: '/requests'
  },
  {
    icon: PeopleIcon,
    desc: 'Client/Patient',
    secondDesc: '',
    badge: 0,
    subList: [],
    path: '/customers'
  },
  {
    icon: ContactsIcon,
    desc: 'Business/Contact',
    secondDesc: '',
    badge: 0,
    subList: [
      {
        icon: BusinessIcon,
        desc: 'Businesses',
        secondDesc: '',
        badge: 0,
        subList: [],
        path: '/businesses'
      },
      {
        icon: PersonIcon,
        desc: 'Contacts',
        secondDesc: '',
        badge: 0,
        subList: [],
        path: '/members'
      }
    ]
  }
];

const navbarPersonalList = [
  {
    icon: HomeOutlinedIcon,
    desc: 'Dashboard',
    secondDesc: '',
    badge: 0,
    subList: [],
    path: '/'
  },
  {
    icon: PeopleIcon,
    desc: 'Requests',
    secondDesc: '',
    badge: 0,
    subList: [],
    path: '/requests'
  }
];



export default process.env.REACT_APP_PORTAL === 'PERSONAL' ? navbarPersonalList : navbarList;
